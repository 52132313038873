import 'bootstrap/dist/css/bootstrap.min.css';
import NagivationBar from './navigation/NavigationBar';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Home/Home';
import About from './About/About';
import Contact from './Contact/Contact';
import Experience from './About/Experience';
import History from './About/History';
import Footer from './Footer/Footer';
import Services from './Services/Services';
import Clients from './Clients/Clients';
import Questions from './Questions/Questions';
import PromoItem from './PromoItem/PromoItem';
import PrivacyPolicy from './Privacy-Policy/Privacy-Policy';
import { ChakraProvider } from '@chakra-ui/react'
import StructuredData from './Header/StructuredData';
import Toast from './UIComponents/Toast';

function App() {
  return (
    <ChakraProvider>
      <StructuredData />
      <Router>
        <div className="App">
          <header className="App-header">
            <PromoItem />
            <NagivationBar />
          </header>
        </div>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/sobre-mi" element={<About/>} />
          <Route path="/contacto" element={<Contact/>}/>
          <Route path="/formacion" element={<Experience/>}/>
          <Route path="/okker-y-yo" element={<History/>}/>
          <Route path="/servicios" element={<Services/>}/>
          <Route path="/opiniones" element={<Clients/>}/>
          <Route path="/preguntas" element={<Questions/>}/>
          <Route path="/politica_de_privacidad" element={<PrivacyPolicy/>} />
        </Routes>
        <Footer/>
      </Router>
    </ChakraProvider>
  );
}

export default App;
