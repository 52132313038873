import { Link } from "react-router-dom"
import "./Questions.css"
import { Accordion } from "react-bootstrap"
import { useEffect } from "react"

export default function Questions() {

    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

    return (
        <div className="container">
            <div className="row">
                <div className="questions">
                    <div className="about-header-title">
                        <h1>Preguntas frecuentes</h1>
                    </div>
                    <Accordion defaultActiveKey="1">                    
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>¿Dónde trabajas?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Trabajo en la ciudad de Segovia (España) y en los pueblos cercanos.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>¿Qué servicios ofreces?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                                <ul>
                                    <li>Paseo sanitario</li>
                                    <li>Paseo urbano</li>
                                    <li>Excursión en la naturaleza</li>
                                    <li>Guardería de día <span className="warning-message">(actualmente no disponible para clientes nuevos)</span></li>
                                    <li>Guardería de varios días <span className="warning-message">(actualmente no disponible para clientes nuevos)</span></li>
                                </ul>
                            Puedes ver más detalles sobre los diferentes servicios <Link to="/servicios">aquí.</Link>
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Quiero que pasees/cuides a mi perro. ¿Qué tengo que hacer?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                                Antes del primer día de paseo/guardería, siempre 
                                hacemos una evaluación previa. Es un encuentro 
                                gratuito cerca de tu domicilio que sirve para 
                                conocernos un poco, hacernos preguntas y aclarar dudas. 
                                De esta forma podemos ver si soy la cuidadora más 
                                adecuada para tu perro y podemos asegurarnos de que 
                                tu peludo la pase bien conmigo. 
                                <p></p>
                                <p>Pide una cita para la evaluación previa <Link to="/contacto">aquí.</Link></p>
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>¿Cuáles son los requisitos para poder contratar tus servicios?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Requisitos generales para todos los servicios:
                            <ul>
                                    <li>Microchip</li>
                                    <li>Plan de vacunación al día: vacuna antirrábica y polivante administradas en el último año.</li>
                                    <li>Desparasitación interna y externa al día</li>
                                    <li>Buen estado de salud</li>
                                    <li>Chapa de identificación o collar personalizado con los datos de contacto del dueño</li>
                                    <li>Evaluación previa gratuita</li>
                                </ul>
                            </p>
                            <p className="a">
                            Adicional para la guardería:
                            <ul>
                                <li>Que el perro sepa hacer sus necesidades afuera</li>
                            </ul>
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>¿Por qué mi perro tiene que tener chapa de identificación?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Como guardianes del perro, es 
                            nuestra responsabilidad (tuya y mía) 
                            cuidarlo y protegerlo y prepararnos para lo inesperado.
                            </p>
                            <p className="a">
                            Si se rompe la correa, si tu perro se asusta y 
                            se escapa o si llega a salir del arnés y se pierde, 
                            una chapa de identificación puede salvarle la vida.
                            </p>
                            <p className="a">
                            Si alguien encuentra a tu perro, es mucho más probable 
                            que intente ayudarle si ve que tiene chapa y no es 
                            callejero. Además, no va a tener que esperar días para 
                            llevarlo al veterinario y leer el microchip, porque va 
                            a tener tus datos de contacto y te va a poder avisar enseguida.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>¿Paseas/cuidas a perros de cualquier edad?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Sí! Paseo y cuido a cachorros (si tienen las 
                            vacunas necesarias para salir a la calle), adolescentes, 
                            adultos y viejitos también.
                            </p>
                            <p className="a">
                            Importante: para poder aceptar a tu perro en la guardería, es necesario que sepa hacer sus necesidades afuera.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>¿Trabajas con perros potencialmente peligrosos (PPP)?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            No, lamentablemente por ahora no puedo trabajar con PPPs.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>A mi perro no le gustan los otros perros. ¿Lo puedes pasear/cuidar igual?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Depende del caso. Si tu perro es miedoso, reactivo, nervioso 
                            o simplemente no quiere interactuar con otros perros, 
                            lo puedo pasear/cuidar sin ningún problema. Pero si es agresivo 
                            con otros perros o con personas, lamentablemente no puedo trabajar con él.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>¿Habrá otros perros en la guardería?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Como cuido a los perros en mi hogar, mi propio perro siempre va a estar ahí, pero 
                            él no va a tener contacto con los huéspedes.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="10">
                            <Accordion.Header>Tengo más de un perro. ¿Puedes pasearlos/cuidarlos juntos?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Sí. Si viven juntos y se llevan bien, por supuesto los puedo pasear/cuidar juntos. 
                            </p>
                            <p className="a">
                            En este caso el segundo perro tendría un descuento de 50%.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="11">
                            <Accordion.Header>¿Haces paseos/guardería en grupos también?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            No. Por ahora solo ofrezco servicios individuales 
                            para poder prestarle toda mi atención a un solo 
                            perro y adaptarme a sus necesidades únicas.
                            </p>
                            <p className="a">
                            Excepción: perros que vivan juntos.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="12">
                            <Accordion.Header>¿Se pueden agendar paseos ocasionales o solo regulares?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Se pueden hacer las dos cosas. Puedo sacar a 
                            tu perro a pasear varias veces a la semana, 
                            pero también puedo ayudarte con un solo paseo en una fecha puntual.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="13">
                            <Accordion.Header>¿Cuál es el horario de los paseos?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Me dedico a los perros a tiempo completo e intento ser lo más flexible posible 
                            con los horarios y adaptarme a las necesidades de cada perro y dueño. 
                            Como el horario exacto del paseo también depende de la disponibilidad 
                            en mi agenda, vale la pena reservar los paseos lo antes posible.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="14">
                            <Accordion.Header>¿Cuánto duran los paseos?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            La duración de los paseos varía entre 30 minutos 
                            y 2 horas.
                            </p>
                            <p className="a">
                            Para ver más detalles de los diferentes paseos, 
                            haz click <Link to="/servicios">aquí.</Link>
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="15">
                            <Accordion.Header>¿Cuál es el horario de la guardería?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            No hay un horario fijo de entrada y salida, pero es importante 
                            que siempre coordinemos los tiempos de antemano para que pueda 
                            estar preparada y disponible para atenderte.
                                <ul>
                                    <li>Guardería de día: cuido a tu perro como máximo 12 horas durante el 
                                    día, sin pasar la noche.</li>
                                    <li>Guardería de varios días: tu perro se queda conmigo durante 
                                    la noche también. El precio de una noche se refiere a una 
                                    estadía máxima de 24 horas.</li>
                                </ul>
                            </p> 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="16">
                            <Accordion.Header>¿Qué pasa si llego tarde a recoger a mi perro?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            En caso de llegar tarde, se cobrará un precio adicional acorde a las horas extras.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="17">
                            <Accordion.Header>¿Cuánto cuestan los paseos/la guardería?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Puedes ver el precio de los diferentes servicios haciendo click <Link to="/servicios">aquí</Link>.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="18">
                            <Accordion.Header>¿Cómo y cuándo te puedo pagar?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Los pagos se hacen en efectivo. 
                                <ul>
                                    <li>Paseos: se pagan al final de cada paseo.</li>
                                    <li>Guardería de día: se paga al final del día.</li>
                                    <li>Guardería de varios días: se abona 
                                        el importe total de la estadía antes de la hora 
                                        de entrada a la guardería.</li>
                                </ul>
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="19">
                            <Accordion.Header>¿Ofreces algún tipo de descuento?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Sí. Si compras un abono de 10 salidas 
                            (de paseo sanitario o de paseo urbano o de excursión en la naturaleza) 
                            por adelantado, obtienes un 10% de descuento. 
                            El abono es válido por 2 meses desde la fecha de compra.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="20">
                            <Accordion.Header>¿Vienes a recoger a mi perro o tengo que llevarlo yo?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                                <ul>
                                    <li>Paseos: voy a tu domicilio para recoger a tu perro y después 
                                    te lo llevo a casa sin ningún coste adicional.</li>
                                    <li>Guardería: puedes traer y recoger a tu perro tú mismo o puedes 
                                    contratar el servicio de recogida y entrega a domicilio por un coste adicional.
                                    <p style={{marginTop: 0, marginBottom:0}}>Segovia - 5 euros</p>
                                    <p style={{margin: 0, padding: 0}}>Otro lugar - preguntar precio</p>
                                    </li>
                                </ul>
                            
                            </p>
                            
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="21">
                            <Accordion.Header>¿Qué pasa si no estoy en casa en el horario del paseo?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Puedes dejarme las llaves de antemano o puedes 
                            pedirle a algún vecino que me abra la puerta.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="22">
                            <Accordion.Header>¿Qué necesito preparar para los paseos?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Paseo sanitario:
                            <ul>
                                <li>collar o arnés con identificación</li>
                                <li>correa</li>
                                <li>chuches (opcional)</li>
                                <li>ropa (si es necesario)</li>
                            </ul>
                            </p>
                            <p className="a">
                            Paseo urbano y excursión en la naturaleza:
                            <ul>
                                <li>collar o arnés con identificación</li>
                                <li>correa</li>
                                <li>chuches</li>
                                <li>juguete (opcional)</li>
                                <li>ropa (si es necesario)</li>
                            </ul>
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="23">
                            <Accordion.Header>¿Qué tiene que llevar mi perro a la guardería?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Guardería de día:
                            <ul>
                                <li>libreta sanitaria</li>
                                <li>collar o arnés con identificación</li>
                                <li>correa</li>
                                <li>chuches</li>
                                <li>juguete (opcional)</li>
                                <li>medicación (si es necesario)</li>
                                <li>ropa (si es necesario)</li>
                            </ul>
                            </p>
                            <p className="a">
                            Guardería de varios días:
                            <ul>
                                <li>libreta sanitaria</li>
                                <li>collar o arnés con identificación</li>
                                <li>correa</li>
                                <li>comida</li>
                                <li>chuches</li>
                                <li>platos (opcional)</li>
                                <li>juguete (opcional)</li>
                                <li>cama/transportín (opcional)</li>
                                <li>ropa (si es necesario)</li>
                                <li>vitaminas, productos antiparasitarios, medicación (si es necesario)</li>
                                <li>herramientas y productos de mantenimiento de pelo (si es necesario)</li>
                                <li>cualquier otra cosa que te parezca necesaria</li>
                            </ul>
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="24">
                            <Accordion.Header>¿Qué va a hacer mi perro en los paseos/en la guardería?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Para ver qué actividades incluyen los diferentes servicios, haz click <Link to="/servicios">aquí</Link>.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="25">
                            <Accordion.Header>¿Los paseos/las actividades de la guardería son iguales para cada perro?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            No. Como cada perro tiene necesidades diferentes, 
                            intento hacer que las actividades sean lo más adecuadas 
                            posibles para cada uno teniendo en cuenta sus 
                            características individuales (edad, raza, nivel 
                            de energía, hábitos, posibles problemas de conducta, 
                            objetivos de los dueños etc).
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="26">
                            <Accordion.Header>¿Cuántas veces va a salir a pasear mi perro en la guardería?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Eso depende de las necesidades del perro. En general hacemos dos paseos durante el día 
                            y uno más por la noche si tu perro se queda conmigo varios días.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="27">
                            <Accordion.Header>¿Qué va a comer mi perro en la guardería?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Cada perro come su propia comida/chuches que trae de casa.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="28">
                            <Accordion.Header>¿Cuánto tiempo va a pasar solo mi perro en la guardería?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Como yo me dedico a los perros a tiempo completo y mi novio también 
                            trabaja desde casa, el tiempo máximo que tu perro pasaría solo serían algunas horas.
                            </p>
                            <p>
                            Si prefieres que tu perro no esté solo en ningún momento, podemos personalizar el 
                            cuidado según tus necesidades específicas.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="29">
                            <Accordion.Header>¿Dónde vas a pasear con mi perro?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Paseos:
                                <ul>
                                    <li>En los paseos sanitarios y en los paseos urbanos paseamos cerca de tu domicilio.</li>
                                    <li>En las excursiones vamos a alguna senda verde o sendero en la naturaleza cerca de Segovia.</li>
                                </ul>
                            </p>
                            <p className="a">
                            Guardería:
                                <ul>
                                    <li>En general paseamos en el pueblo o en el campo cerca de mi 
                                        casa, y a veces vamos de excursión a alguna senda verde o 
                                        sendero en la naturaleza cerca de Segovia.</li>
                                </ul>
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="30">
                            <Accordion.Header>¿Vas a llevar a mi perro al pipican/parque canino?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            No. Según mi experiencia profesional, los pipicanes tienen más riesgos que beneficios.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="31">
                            <Accordion.Header>¿Mi perro va a jugar con otros perros durante los paseos?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            En general no. Durante los paseos me enfoco en construir un vínculo con tu perro y cubrir 
                            sus necesidades yo misma. Jugamos, exploramos, hacemos ejercicios divertidos y nos relajamos
                            juntos. Y si nos cruzamos con otros perros, es una excelente oportunidad para practicar cómo 
                            ser neutros y tranquilos y cómo prestar atención al guía en la presencia de otros perros sin 
                            interactuar con ellos.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="32">
                            <Accordion.Header>¿Sueltas a los perros durante los paseos?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            En el 99% de los casos no. 
                            Para mí, lo más importante es la 
                            seguridad de los perros, llevarlos 
                            a casa sanos y salvos.
                            </p>
                            <p className="a">
                            Para poder soltar a un perro necesito 
                            que tenga una llamada muy estable, que 
                            nos conozcamos muy bien, que tengamos una 
                            relación fuerte y confianza mutua, que 
                            estemos en un entorno ideal y que el dueño me dé permiso.
                            </p>
                            <p className="a">
                            Para darles más libertad a los perros de una forma segura, 
                            uso correas largas de 8-10 metros.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="33">
                            <Accordion.Header>¿Mi perro se va a llevar bien contigo?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Voy a hacer todo lo posible para que tu perro disfrute las actividades 
                            y que le guste pasar tiempo conmigo. Ya he trabajado con muchos perros 
                            diferentes. Algunos me aceptaron enseguida, otros necesitaban más tiempo 
                            para acostumbrarse a mí. Eso es totalmente normal. La evaluación previa 
                            me ayuda mucho para entender a tu perro y hacernos amigos.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="34">
                            <Accordion.Header>¿Voy a notar algún cambio en mi perro si lo paseas/cuidas regularmente?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Mi objetivo es ayudar a satisfacer las necesidades de tu perro 
                            y así lograr que sea más feliz, equilibrado y contento.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="35">
                            <Accordion.Header>¿Cómo viajan los perros en tu coche?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Por cuestiones de seguridad, todos mis clientes 
                            perrunos viajan en un transportín o con un arnés y cinturón de seguridad.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="36">
                            <Accordion.Header>¿Qué pasa si ocurre una emergencia durante el paseo/en la guardería?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Te aviso enseguida y si es necesario, llevo a tu perro al veterinario.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="37">
                            <Accordion.Header>¿Si hace mal tiempo sales con mi perro igual?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            En general sí. No importa si llueve, nieva, hace frío o hace calor, 
                            los perros necesitan salir igual. Si me veo obligada a cancelar o 
                            reprogramar el paseo (p. ej. por una tormenta muy fuerte), me pongo 
                            en contacto contigo.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="38">
                            <Accordion.Header>¿Qué pasa si tengo que cancelar mi reserva?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Tienes tiempo para cancelar tu reserva hasta 24 horas antes de la hora del comienzo del servicio.
                                <ul>
                                    <li>Paseos: si cancelas dentro de 24 horas, el precio a abonar es la mitad del precio del paseo.</li>
                                    <li>Guardería de día: si cancelas dentro de 24 horas, el precio a abonar es la mitad del precio del día.</li>
                                    <li>Guardería de varios días: si cancelas dentro de 24 horas, el precio a abonar es el precio de la primera noche.</li>
                                </ul>
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="39">
                            <Accordion.Header>No encontré la respuesta para mi pregunta. ¿Qué hago?</Accordion.Header>
                            <Accordion.Body>
                            <p className="a">
                            Mándame un mensaje haciendo click <Link to="/contacto">aquí</Link>.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}