import './Home.css'
import okkerSegovia from "../img/okkerpaseo.webp"
import dogPee from "../img/dog pee2.png"
import cityPic from "../img/city2.png"
import naturePic from "../img/nature.png"
import sunPic from "../img/sun.png"
import moonPic from "../img/moon.png"
import okkerSentado from "../img/okkersentado.webp"
import {Link} from "react-router-dom"
import { useEffect } from 'react'

export default function Home() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    return (
        <div className='home-container'>
            <div className="home-header">
                <img className="picture-header" src={okkerSegovia} alt="A dog in the countryfield"/>
            </div>
            <div className="home-header-overlay-title">
                <h1>Cuidadora y paseadora profesional de perros<br></br> en Segovia y alrededores</h1>
            </div>
            <div className="home-header-overlay-logo">
                <h1>FLOR PASEA</h1>
            </div>
            <div className="home-container-text">
                <h2>¡Hola, bienvenido a mi página!</h2>
                <h4>Si llegaste hasta aquí, significa que eres un dueño responsable, 
                    amas a tu compañero peludo y quieres la mejor vida para él. Yo estoy aquí para ayudarte en eso.</h4>
            </div>
            <div className="wrap-alter">
                <div className="home-container-sub-text">
                    <h2>Elige el servicio que más te convenga</h2>
                </div>
                <div className="text-columns-3">
                    <div className="max-width-gradient">
                        <div className="image-gradient-container">
                            <Link to="/servicios#paseo-sanitario" style={{height: 150, width: 150, zIndex: 5, position: 'absolute', borderRadius: 100}}></Link>
                            <div className="image-gradient"></div>
                            <img className="dog" src={dogPee} alt="Dibujo de un perro orinando" />
                            
                        </div>
                        <Link to="/servicios#paseo-sanitario" style={{color: 'black'}}><h3>Paseo sanitario</h3></Link>
                        <p>
                            Perfecto para perros mayores que ya no requieren paseos largos, 
                            para cachorros que tienen que salir varias veces al día y para 
                            perros que necesitan una salida rápida en un día largo de trabajo.
                        </p>
                    </div>
                    <div className="max-width-gradient">
                        <div className="image-gradient-container">
                            <Link to="/servicios#paseo-urbano" style={{height: 150, width: 150, zIndex: 5, position: 'absolute', borderRadius: 100}}></Link>
                            <div className="image-gradient"></div>
                            <img className="city" src={cityPic} alt="Dibujo de una ciudad" />       
                        </div>
                        <Link to="/servicios#paseo-urbano" style={{color: 'black'}}><h3>Paseo urbano</h3></Link>
                        <p>
                            Paseo estándar, ayuda a cubrir las necesidades básicas de los perros 
                            que tienen un nivel de energía medio o los que habitualmente pasean 
                            por la ciudad.
                        </p>
                    </div>
                    <div className="max-width-gradient">
                        <div className="image-gradient-container">
                            <Link to="/servicios#excursion" style={{height: 150, width: 150, zIndex: 5, position: 'absolute', borderRadius: 100}}></Link>
                            <div className="image-gradient"></div>
                            <img className="forest" src={naturePic} alt="Dibujo de un sol" />
                        </div>
                        <Link to="/servicios#excursion" style={{color: 'black'}}><h3>Excursión en la naturaleza</h3></Link>
                        <p>
                            Ideal para perros que necesitan más actividad física y mental, que 
                            aman estar al aire libre o que se ponen nerviosos por los estímulos de la ciudad.
                        </p>
                    </div>

                    <div className="max-width-gradient" style={{position: "relative"}}>
                        <div className="image-gradient-container">
                            <Link to="/servicios#guarderia-de-dia" style={{height: 150, width: 150, zIndex: 5, position: 'absolute', borderRadius: 100}}></Link>
                            <div className="image-gradient"></div>
                            <img className="sun" src={sunPic} alt="Dibujo de una luna" />
                        </div>
                        <Link to="/servicios#guarderia-de-dia" style={{color: 'black'}}><h3>Guardería de día</h3></Link>
                        <p className="warning-message">Actualmente no disponible para<br></br> clientes nuevos.</p>
                        <p>
                        Ideal si tienes un día complicado y no puedes dedicarle mucho tiempo a tu perro, 
                        si pasas todo el día fuera de casa y no quieres que tu perro esté tantas horas solo 
                        o si quieres que tenga un día más activo.
                        </p>
                    </div>

                    <div className="max-width-gradient" style={{position: "relative"}}>
                        <div className="image-gradient-container">
                            <Link to="/servicios#guarderia-de-varios-dias" style={{height: 150, width: 150, zIndex: 5, position: 'absolute', borderRadius: 100}}></Link>
                            <div className="image-gradient"></div>
                            <img className="moon" src={moonPic} alt="Dibujo de un paisaje natural" />
                        </div>
                        <Link to="/servicios#guarderia-de-varios-dias" style={{color: 'black'}}><h3>Guardería de varios días</h3></Link>
                        <p className="warning-message">Actualmente no disponible para<br></br> clientes nuevos.</p>
                        <p>
                        La solución perfecta si tienes que hacer un viaje de varios días o te vas de 
                        vacaciones y no tienes con quien dejar a tu perro.
                        </p>
                    </div>
                </div>
                <div className="home-container-note">
                    <p>Para saber más de cada servicio, <Link to="/servicios">haz click aquí.</Link></p>
                </div>
            </div>
            <div>
                <img src="https://res.cloudinary.com/dovuoehfu/image/upload/v1673192232/flor_pasea/IMG_20211026_123949_sefjsn.jpg" alt="" className="hero-image-2"/>
            </div>
            <div className="home-container-text choose-me">
                <div className="choose-me-one">
                    <h2>¿Por qué elegirme?</h2>
                    <ul>
                        <li>
                            Formación profesional en adiestramiento y educación canina
                        </li>
                        <li>
                            Varios años de experiencia trabajando con perros
                        </li>
                        <li>
                            Diferentes servicios para adaptarme a las necesidades de tu perro
                        </li>
                        <li>
                            Evaluación previa gratuita para asegurarnos de que tu peludo se la pase bien conmigo
                        </li>
                        <li>
                            Cuidado individual para que tu perro tenga toda mi atención
                        </li>
                        <li>
                            Recogida y entrega a domicilio para tu comodidad
                        </li>
                        <li>
                            Fotos/videos de tu perro para mantenerte al tanto
                        </li>
                    </ul>
                </div>
                <div>
                    <img className="home-hero-image" src={okkerSentado} alt="Perro sentado mirando a la cámara" />
                </div>
            </div>
            <div className='center wrap-alter padding'>
                <h2>¿Tienes más preguntas?</h2>
                <p>Si no estás seguro de cuál es el mejor servicio para tu perro, contáctame y te ayudo a elegir.</p>
                <Link to="/contacto">
                    <div className="home-box-button">
                        Contactar
                    </div>
                </Link>
            </div>
            <div className="home-container-text">
                <div className="d-flex-center">
                    <h3>Échales un vistazo a mis publicaciones de Instagram</h3>
                </div>
                <div className="instagram-posts-wrapper">
                    <div className="instagram-posts">
                        
                        <div className="instagram-post">
                            <a href="https://www.instagram.com/p/DBT-iH_M5_X/">
                                <div className="instagram-post-hover"></div>
                                <img src="https://res.cloudinary.com/dovuoehfu/image/upload/v1730053232/flor_pasea/instagram/anuncio_phay8e.jpg" alt="" />
                            </a>
                        </div>
                        <div className="instagram-post">
                            <a href="https://www.instagram.com/p/DBTYI-Hszrv/">
                                <div className="instagram-post-hover"></div>
                                <img src="https://res.cloudinary.com/dovuoehfu/image/upload/v1730053232/flor_pasea/instagram/berlin_ot8nna.jpg" alt="" />
                            </a>
                        </div>
                        <div className="instagram-post">
                            <a href="https://www.instagram.com/p/DBOVoMjsCpx/">
                                <div className="instagram-post-hover"></div>
                                <img src="https://res.cloudinary.com/dovuoehfu/image/upload/v1730053232/flor_pasea/instagram/kinder_xjhcrb.jpg" alt="" />
                            </a>
                        </div>
                        <div className="instagram-post">
                            <a href="https://www.instagram.com/p/DBL6pGCMF2d">
                                <div className="instagram-post-hover"></div>
                                <img src="https://res.cloudinary.com/dovuoehfu/image/upload/v1730053232/flor_pasea/instagram/dobby_twab2q.jpg" alt="" />
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}