import "./Footer.css"
import {FaInstagram} from "react-icons/fa"
import {FiMail, FiMapPin} from "react-icons/fi"
import {BsWhatsapp} from "react-icons/bs"
import { Link } from "react-router-dom"

export default function Footer() {
    return (
        <footer>
            <div className="footer-main">
                <div className="footer-contact">
                    <div className="footer-contact-title">
                        <h6>Virág Martinkó</h6>
                        <hr/>
                    </div>
                    <div className="footer-contact-details">
                        <div className="footer-contact-details-inner gap-20">
                            <FaInstagram className="instagram-logo" size={18}/>
                            <BsWhatsapp/>
                            <FiMail />
                            <FiMapPin/>
                        </div>
                        <div className="footer-contact-details-inner">
                            <p>@flor_pasea</p>
                            <p>+34664468931</p>
                            <p>flor@florpasea.com</p>
                            <p>Segovia (España) y alrededores</p>
                        </div>                        
                    </div>
                </div>
                <div className="footer-CTA">
                    <img src="/Dog paw-amico.svg" alt="Drawing of pet dogs saying Hi"/>
                    <Link to="/contacto">
                        <div className="footer-button">
                            Hacer reserva
                        </div>
                    </Link>
                </div>
            </div>
            <div className="copyright-terms">
                <p>Copyright© {new Date().getFullYear()} Virág Martinkó - Flor Pasea</p>
                <p><Link to="/politica_de_privacidad">Política de Privacidad</Link></p>
            </div>
        </footer>
    )
}