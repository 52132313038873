export const clientReviews = [
    {
        title: "Ana & Byron",
        body: "Flor se ha quedado tres noches con nuestro Byron. Es una persona muy bien formada por como trata a los perros con tanto cariño. Mi perro ha estado súper a gusto en su casa, con muchos cuidados y también con muchos mimos. Nos ha mantenido informados por WhatsApp de lo que estaba haciendo Byron en todo momento y eso nos ha dado mucha tranquilidad. Estamos muy agradecidos de que se haya quedado con nuestro perrito y sin duda la contactaremos para próximas ocasiones.",
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1729876916/flor_pasea/opiniones/Byron_bdsp6i.jpg"
    },
    {
        title: "Alicia, Ismael & Jacko",
        body: "Era la primera vez que dejábamos a Jacko con una persona que no conocíamos, y Flor 🩷 desde el primer momento no ha trasmitido mucha confianza y amor hacia nuestro peludito. Recomendable 100%, por su atención, cuidado y dedicación. Si tengo que volver a dejar a Jacko, Flor sería la cuidadora de Jackete. Ha cubierto todas las necesidades de Jacko. Millones de gracias, Flor 😘😘😘 !¡",
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1729876953/flor_pasea/opiniones/Jacko_ojihzh.jpg"
    },
    {
        title: "Gabriela & Berlín",
        body: "Excelente trabajo de Flor, mi perro Berlín se nota contento con su cuidado, le encantan los paseos por el campo🥰 Sin duda una de las mejores cuidadoras de perros en Segovia 👍🏻 100% recomendado",
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1718744136/flor_pasea/opiniones/Berl%C3%ADn_mssh0f.jpg"
    },
    {
        title: "Ana, Jesús & Trufa",
        body: "La mejor opción para nuestra peluda en Segovia! Virág es súper atenta, ha cuidado maravillosamente bien de nuestra perra, que es nerviosa y asustadiza, y con la cantidad de fotos y vídeos que nos ha mandado hemos podido comprobar lo bien que ha estado con ella, tranquila, calmada, y muy, muy feliz!",
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1718744136/flor_pasea/opiniones/Trufa_gvwfay.jpg"
    },
    {
        title: "Mari Carmen & Coco",
        body: "Virág es muy responsable y con mucha experiencia. Nuestro perrito estuvo muy bien cuidado, nos informaba regularmente de cómo estaba y lo que hacía y quedamos muy contentos. Y Coco también. Gracias, Virág! Repetiremos! 😊",
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1718744136/flor_pasea/opiniones/Coco_jxmc1b.jpg"
    },
    {
        title: "Lorena, Fernando & Timón",
        body: "Virág (alias Flor) ha sido nuestra salvación desde que estamos en Segovia. Mi pareja y yo adoptamos un perrito, Timón, él es todo para nosotros, pero tiene ansiedad por separación y cuando tenemos algún compromiso, siempre intentamos buscar alternativa para que esté con alguien. Con Flor hemos acertado totalmente, pues puede pasear muy cerca del campo y jugar disfrutando de la naturaleza, es una persona muy bien formada y cariñosa con los perretes, nos mantiene informados durante el día vía WhatsApp de qué cosas está haciendo Timón, le hace muchas fotos y vídeos para que estemos tranquilos. Nuestro perro es miedoso, y en pocos días que hemos precisado su servicio (incluso para dormir) ha estado muy a gusto y acompañado. Estamos muy agradecidos a su servicio, ya que hay pocos en Segovia... Nos encantará seguir contando con ella para nuevas ocasiones.",
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1710112301/flor_pasea/opiniones/Tim%C3%B3n_uj2tzm.jpg"
    },
    {
        title: "Iris & Lola",
        body: "Flor es muy profesional y atenta, me ha preguntado de todo sobre la mascota que le pasé y se asegura de que está totalmente preparada para brindar servicios de calidad. También hago paseos de perros y seguro que le tomaré como referencia. Recomiendo altamente!!",
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1707054209/flor_pasea/opiniones/Lola_bltlgc.jpg"
    },
    {
        title: "Javier & Leia",
        body: `Pues ha sido todo un descubrimiento. Solo puedo decir cosas increíbles, vino a casa a conocer primeramente a Leia, habló con nosotros y que le contásemos  todo sobre ella, mientras tanto ella se percataba de nuestra perrita e iba estudiándola. Posteriormente el día en que la dejamos, nos ha estado informando de todo lo que hacían durante el día, nos mandaba fotos y vídeos tanto por la mañana como por la tarde, daban paseos por la mañana y la tarde, han trabajado cositas que tiene que mejorar Leia poco a poco, han hecho olfateo, en casa han jugado, ha masticado, la han dado mucho amor y Leia creo que ha estado increíblemente bien. Me alegro de que se hayan cruzado nuestros caminos y Leia está encantada. Repetiremos con Flor la próxima vez que Leia necesite quedarse con alguien. Muchísimas gracias Flor por estos tres días y por el contacto tan fluido que has tenido con nosotros. Hasta pronto.`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1707054209/flor_pasea/opiniones/Leia_ywiath.jpg"
    },
    {
        title: "Pedro & Bruno",
        body: `Estamos totalmente encantados con Virág, sin duda volveré a repetir con ella.
        Muy atenta con Bruno y con nosotros, sus paseos, dedicación con Bruno, detallista con 
        sus costumbres y el tiempo que dedica al entrenamiento mental. Muchas gracias Virág.`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706445616/flor_pasea/opiniones/WhatsApp_Image_2024-01-24_at_18.50.08_iavr8f.jpg"
    },
    {
        title: "Diana & Kenya",
        body: `Hace ya algún tiempo que conozco a Virág y no hay mejor persona a la 
        que poder dejar a tu perro a cargo. Va a preocuparse de las necesidades de 
        cada uno de ellos y dará todo de sí para cubrirlas. Se nota que tiene formación 
        y experiencia con más perritos, además es muy atenta y se nota que son su 
        vocación! Los perros la adoran y nosotros también!!!`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1705707211/flor_pasea/opiniones/WhatsApp_Image_2024-01-16_at_21.34.56_dq7u1q.jpg"
    },
    {
        title: "Alicia & Boy",
        body: `Mejor imposible. Nos fuimos 10 días de vacaciones y tuvimos la gran 
        suerte de que Flor cuidase a nuestro peludo.
        Nos ha ido informando cada día con fotos y vídeos, 
        resolutiva solucionando un problema que tenía en el oído gracias 
        a sus conocimientos, con juegos y paseos, hasta ha vuelto mejor educado. 
        Gran profesional. Repetiremos sin dudarlo en próximas ocasiones.
        `,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1705707211/flor_pasea/opiniones/IMG_20240116_230655_wngpiu.jpg"
    },
    {
        title: "Monica & Lolo",
        body: `Si quieres dejar a tu mejor amigo en las mejores manos esta es la 
        opción!!! Flor es un encanto, cariñosa y cuida de nuestros peludos como si 
        fuesen suyos!!!!!!`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1705707211/flor_pasea/opiniones/WhatsApp_Image_2024-01-16_at_22.13.20_cmdll5.jpg"
    },
    {
        title: "Ana & Kuma",
        body: `Virág ha cuidado y paseado a mi perro en varias 
        ocasiones. Siempre confío en ella cuando no puedo estar en 
        casa. Es una chica encantadora, que entiende mucho sobre los 
        perros y su lenguaje. Mi perro tiene algunos miedos, y no confía 
        mucho en las personas, pero con ella se entendió perfectamente, 
        además de que le dio su tiempo para adaptarse. Respeta y adora 
        a los perros, y eso se nota en su trabajo!!
        Además siempre manda fotitos cuando está con Kuma, y me explica 
        cómo han ido los paseos o la estancia en casa, y eso es un buen plus.
        `,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1705707211/flor_pasea/opiniones/WhatsApp_Image_2024-01-16_at_22.10.41_p4mu13.jpg"
    },
    {
        title: "Belén, Daniel & Cala",
        body: `Los paseos con Virág han sido todo un descubrimiento para cuando necesitamos ayuda 
        para compaginar el trabajo con los paseos de nuestra Border Collie, Cala. Tras 
        contactar con ella, tuvimos una primera sesión para conocernos con un montón de 
        preguntas súper completas para entender bien a nuestra perra y sus necesidades. 
        Virág ha sido siempre muy amable y un encanto, además es flexible con los horarios 
        de los paseos. Cala está contentísima cada vez que la ve llegar y nosotros muy 
        tranquilos dejándola con ella. Llevamos ya dos bonos de paseos y seguro que repetiremos, 
        hemos tenido mucha suerte con Virág!`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1705435207/flor_pasea/opiniones/IMG_20230731_212250_t61acu.jpg"
    },
    {
        title: "Reyes & Alice, Cooper",
        body: `Muchas gracias por los paseos a nuestros peludos mientras estábamos de 
        vacaciones, la familia está muy contenta contigo, y me comentan que los 
        perretes se alegraban mucho cuando llegabas, así que gracias!!!`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1705435207/flor_pasea/opiniones/InShot_20230731_211249656_awzhlk.jpg"
    },
    {
        title: "Sára, Kriszti & Süti, Theo, Penny, Bora",
        body: `     Viri ha estado varias veces con nosotros como cuidadora a domicilio. 
                    Siempre la hemos dejado a cargo de la casa, el jardín y las gallinas, 
                    además de nuestros 4 perros. Tenemos perros viejos, jóvenes, pequeños y 
                    grandes, cada uno con necesidades diferentes, pero Viri les brinda atención 
                    individual a cada uno. En general nos devuelve la casa más limpia que como la 
                    dejamos, aunque la limpieza no forma parte de su contrato.
               
                    Tiene varios años de experiencia con perros, y durante el tiempo que estuvo con 
                    los nuestros hizo cosas que nadie más se hubiera animado a hacer (caminó con 3 
                    perros al bosque cercano, ahí dieron una vuelta grande y luego volvieron a casa).
               
                    Viri es exigente consigo misma y con su entorno, una chica atenta y amable a la que 
                    le gusta estar rodeada de animales, que la quieren y están relajados en su 
                    presencia. Se mantiene en contacto con nosotros en todo momento y nos ilustra la 
                    situación actual con fotos, lo que siempre es tranquilizador y un gesto muy amable.
                    `,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706446043/flor_pasea/opiniones/Clientes_1_l8mxqz.jpg"
    },
    {
        title: "Rita & Fahéj",
        body: `Virág ha sido una cuidadora FANTÁSTICA. Es muy flexible, amable y confiable. 
        A Fahéj le ha encantado estar con ella. Le agradecemos mucho la ayuda por este 
        medio también.`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706446044/flor_pasea/opiniones/Clientes_6_ndfyar.jpg"
    },
    {
        title: "Niki & Kronos",
        body: `Amable, flexible. ❤️ La recomiendo absolutamente.`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706446044/flor_pasea/opiniones/Clientes_8_hfelt4.jpg"
    },
    {
        title: "Szandra & Manó, Hacker",
        body: `Soy muy cuidadosa a la hora de elegir una persona para cuidar a nuestras 
        mascotas, ya sea para un viaje o simplemente para un paseo. Confío en Virág 
        porque ella cuida de los perros que tiene a su cargo como si fueran suyos. Con 
        respecto a los paseos, la tiramos a la parte honda de una con un husky, que manejó 
        de manera impecable. Y en cuanto al cuidado, recibimos fotos y pequeños informes de 
        situación para saber que todo iba bien en casa. Virág no solo es confiable, sino 
        que es amable y atenta, y se preocupa de verdad por la personalidad y el bienestar 
        de los perros a su cargo. Obtiene puntos extras por ser flexible y adaptarse a las 
        necesidades de los dueños. La recomiendo de corazón.`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706446043/flor_pasea/opiniones/Clientes_2_xlovxj.jpg"
    },
    {
        title: "Bíborka & Gomez",
        body: `Somos muy afortunados por haber encontrado a Virág, 
        cuida a Gomez de manera profesional y nuestro cachorro 
        loquito es cada vez más listo. Ella es una chica inteligente, 
        amable, confiable, la recomiendo a todo el mundo.`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706446044/flor_pasea/opiniones/Clientes_5_yehfna.jpg"
    },
    {
        title: "Annamária & Fifi, Nabu",
        body: `Para nosotros es muy difícil encontrar un cuidador bueno, 
        ya que mis dos perros son bastante especiales. Nabu porque 
        es súper activa y la estamos preparando para ser perra 
        de terapia, y Fifi porque es "curado de agresión por miedo" 
        y es mayor. No es fácil manejar a los dos perros traviesos 
        al mismo tiempo, pero Virág siempre aborda las diferentes 
        situaciones de una manera excelente, consciente y muy profesional. 
        Creo que mis perros se la pasan tan bien cuando los cuida Virág 
        que no me extrañan ni un poco.`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706446044/flor_pasea/opiniones/Clientes_7_uzipit.jpg"
    },
    {
        title: "Berni & Bella ",
        body: `Virág fue muy amable y flexible. Paseó a Bella dos veces, 
        pero antes nos encontramos para conocernos un poco. Supe 
        enseguida que le podía confiar mi perra porque su personalidad 
        era muy calma y se notaba que actuaba de forma deliberada durante 
        el encuentro. Tanto es así que hasta mi bull saltarina recibió totalmente 
        calmada a la nueva invitada :) Es una profesional experimentada, 
        por lo que la recomiendo con gusto :)`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706446043/flor_pasea/opiniones/Clientes_3_d9vxfa.jpg"
    },
    {
        title: "Orsi & Lili  ",
        body: `Virág es una cuidadora perfecta. Totalmente confiable, 
        responsable, hizo todo exactamente como se lo pedí. 
        Fue puntual, cada día recibí un informe detallado con fotos 
        o videos. Nosotros tenemos una perra salchicha que es un poco 
        nerviosa con otros perros y personas, necesita tiempo para 
        amigarse. Virág manejó esta dificultad a la perfección. En un 
        paseo fui testigo de que manejó un conflicto entre perros de manera 
        totalmente profesional. No es de extrañar, ya que también tiene 
        mucha experiencia como entrenadora. No solo es una amante de los 
        perros, sino una profesional con mucho conocimiento. Puedo recomendarla 
        a todo el mundo de corazón.`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706446044/flor_pasea/opiniones/Clientes_4_s20eit.jpg"
    },
    {
        title: "Viki & Viri",
        body: `A Virág le confiaría cualquier perro tranquilamente - las mías han estado con ella 
        varias veces porque sé que siempre están en buenas manos gracias a sus conocimientos y su experiencia.`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706446044/flor_pasea/opiniones/Clientes_9_kc6hjz.jpg"
    },
    {
        title: "Ari & Padme",
        body: `Virág fue muy amable y atenta. A mi perra le gusta muy poca gente y me daba un poco de 
        miedo dejarla, pero Virág me mandó fotos todo el tiempo, me mantuvo al tanto y, gracias 
        a su paciencia angelical, Padme se abrió con ella y se dejó acariciar - ¡eso es, sin 
        dudas, increíble! La recomiendo a cualquiera absolutamente 😊`,
        imageLink: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706446045/flor_pasea/opiniones/Clientes_10_ocy8qu.jpg"
    },
]