import "./Contact.css"
import { Link } from "react-router-dom"
import OkkerContact from "../img/okker-otoño-low.webp"
import { useEffect, useState } from "react"
import { BsFillCheckCircleFill } from "react-icons/bs"

export default function Contact() {
    
    const [name, setName] = useState("")
    const [dogName, setDogName] = useState("")
    const [sendersEmail, setSendersEmail] = useState("")
    const [wpNumber, setWpNumber] = useState("")
    const [message, setMessage] = useState("")
    const [contactResponse, setContactResponse] = useState(false)
    const [contactPrivacy, setContactPrivacy] = useState(false)

    const [errors, setErrors] = useState({})

    const errorsList = {
        name: false,
        dogName: false,
        sendersEmail: false,
        message: false,
        inputResponse: false,
        inputPrivacy: false
    }

    const [unsent, setUnsent] = useState(true)

    const formErrors = () => {

        let hadErrors = false

        if (name.length === 0) {
            errorsList.name = true
            hadErrors = true
        }

        if (dogName.length === 0) {
            errorsList.dogName = true
            hadErrors = true
        }

        if ((sendersEmail.length === 0) | (!sendersEmail.includes("@"))) {
            errorsList.sendersEmail = true
            hadErrors = true
        }

        if (message.length === 0) {
            errorsList.message = true
            hadErrors = true
        }

        if (!contactResponse) {
            errorsList.inputResponse = true
            hadErrors = true
        }

        if (!contactPrivacy) {
            errorsList.inputPrivacy = true
            hadErrors = true
        }

        setErrors(errorsList)

        if (hadErrors) {
            return true
        } else {
            return false
        }
    }

    const sendEmail = () => {

        if (formErrors()) {
            return
        }

        const textMessage = `
            <h1>Un potencial cliente pregunta sobre tus servicios</h1>
            <ul>
                <li>Nombre: ${name}</li>
                <li>Nombre del perro: ${dogName}</li>
                <li>Número de WhatsApp: ${wpNumber}</li>
                <li>Correo electrónico: ${sendersEmail}</li>
            </ul>
            <p><b>Mensaje del cliente</b></p>
            <p>${message}</p>
        `

        const data = {
            to: "flor@florpasea.com",
            text: textMessage,
            replyTo: sendersEmail,
            name: name
          }

          fetch( process.env.REACT_APP_NOTIFICATIONS_SERVICE, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json' 
            },
            body: JSON.stringify(data)
          }).then(
            (response)=>{
                if (response.status === 200) {
                    setUnsent(false)
                }

            }
        ).catch(
            (err)=> {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])
    
      return (
        <>
            <div className="about-header-title">
                <h1>Contacto</h1>
            </div>
            <div className="contact-content">
                <div className="contact-message-area">
                    <img src={OkkerContact} alt="Perro mirando a la cámara en otoño." />
                </div>
                <div className="contact-form-area">
                    <h2>Mándame un mensaje</h2>
                    <p>
                    ¿Te gustaría que te ayude con tu perro? Envíame un mensaje por aquí y te contacto para elegir una fecha para la <strong>evaluación previa gratuita</strong>.
                    </p>
                    <p>
                    Si no encontraste la <strong>respuesta a tus preguntas</strong> en las <Link to="/preguntas">Preguntas Frecuentes</Link> o si necesitas más información, no dudes en ponerte en contacto conmigo.
                    </p>
                    {
                        unsent ?
                        <>
                            <div className="contact-form" id="contact-form">
                                <div className="form-inputs">
                                    <div className="input-group-contact">
                                        <input type="text" placeholder="Nombre *" onChange={(e)=>{setName(e.target.value)}}/>
                                        {errors.name && <span>Ingresa tu nombre.</span>}
                                    </div>
                                    <div className="input-group-contact">
                                        <input type="text" placeholder="Nombre del perro *" onChange={(e)=>{setDogName(e.target.value)}}/>
                                        {errors.dogName && <span>Ingresa el nombre de tu perro.</span>}
                                    </div>
                                    <div className="input-group-contact">
                                        <input type="text" placeholder="Correo electrónico *" onChange={(e)=>{setSendersEmail(e.target.value)}}/>
                                        {errors.sendersEmail && <span>Ingresa un mail válido.</span>}
                                    </div>
                                    <input type="text" placeholder="Número de whatsapp" onChange={(e)=>{setWpNumber(e.target.value)}}/>
                                </div>
                                <div className="input-group-contact-message">
                                    <textarea name="message" cols="10" rows="6" placeholder="Mensaje *"  onChange={(e)=>{setMessage(e.target.value)}}></textarea>
                                    {errors.message && <span>Ingresa tu consulta.</span>}
                                </div>
                            </div>
                            <div>
                                <div className="privacy-inputs">
                                    <input type="checkbox" onChange={()=> setContactResponse(!contactResponse)}/>
                                    <p>Acepto recibir respuesta.*</p>
                                    {errors.inputResponse && <span>Campo obligatorio.</span>}
                                </div>
                                <div className="privacy-inputs">
                                    <input type="checkbox" onChange={()=> setContactPrivacy(!contactPrivacy)}/>
                                    <p>He leído y acepto la <a href="https://www.florpasea.com/politica_de_privacidad" target="_blank" rel="noreferrer">Política de Privacidad</a>.*</p>
                                    {errors.inputPrivacy && <span>Campo obligatorio.</span>}
                                </div>
                            </div>
                            <div className="send" onClick={sendEmail}>
                                <p>Enviar</p>
                            </div>
                        </>
                        :
                        <div className="form-sent">
                            <div className="green-check-form">
                            <BsFillCheckCircleFill />
                            </div>
                            <p>Gracias por ponerte en contacto. Te responderé el mail lo antes posible!</p>
                        </div>
                    }
                    
                </div>
            </div>
        </>
    )
}