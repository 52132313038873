import MainButton from "./MainButton.js"
import "./PriceBox.css"

export default function PriceBox({price, time, serviceName, serviceDetails, serviceImage, idName, disabled}) {
    return (
        <div className="priceBox" id={idName}>
            <div>
                
                <h3 style={{width: "100%"}}>{serviceName} </h3>
                {disabled && <p className="warning-message">Actualmente no disponible para clientes nuevos.</p>}
                <h4><span style={{fontWeight: "bold", color: "#ffbc03"}}>{price} eur</span> / {time}</h4>
                
                <hr></hr>
                <ul>
                    {
                        serviceDetails.length > 0 &&
                        serviceDetails.map((detail, index) => <li key={index}>✓ {detail}</li>)
                    }
                </ul>
                { !disabled &&
                <div className="main-button-container">
                    <MainButton path="/contacto" text="Reservar" />
                </div>
                }
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
                <img alt={`${serviceName} de perrito`}
                style={{borderRadius: 300, objectFit: "cover", width: 250, height: 250}}
                src={serviceImage} 
                />
            </div>
        </div>
    )
}